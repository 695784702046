.fileContainer {
    overflow: hidden;
    position: relative;
    background: #f3f3f3;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .fileContainer [type='file']:focus {
    outline: none;
  }
  
  .fileContainer:hover {
    background: #e8e8e8;
  }
  
  .fileContainer [type='file'] {
    cursor: inherit;
    display: block;
    font-size: 10px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }
  